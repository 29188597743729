import React, { Component } from "react"

import Layout from "../components/layout"
import DashboardSummary from "./dashboardSummary"
// import AboutPage from "./about"
import ProjectsPage from "./projects"
import ContactPage from "./contact"

export default class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <DashboardSummary />
        {/**
        
          <AboutPage />
        */}
        <ProjectsPage />
        <ContactPage />
      </Layout>
    )
  }
}
